"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#E24329", d: "M100,173.7L129.5,83H70.5L100,173.7L100,173.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FC6D26", d: "M100,173.7L70.5,83H29.3L100,173.7L100,173.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FCA326", d: "M29.3,83l-9,27.6c-0.8,2.5,0.1,5.3,2.2,6.8l77.5,56.3L29.3,83L29.3,83z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#E24329", d: "M29.3,83h41.3L52.8,28.4c-0.9-2.8-4.9-2.8-5.8,0L29.3,83L29.3,83z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FC6D26", d: "M100,173.7L129.5,83h41.3L100,173.7L100,173.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FCA326", d: "M170.7,83l9,27.6c0.8,2.5-0.1,5.3-2.2,6.8L100,173.7L170.7,83L170.7,83z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#E24329", d: "M170.7,83h-41.3l17.7-54.6c0.9-2.8,4.9-2.8,5.8,0L170.7,83L170.7,83z" })] }) })));
}
exports.default = Svg;
