"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { x: 0, style: {
                    fill: "#3664A2",
                }, width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { style: {
                    fill: "#FFFFFF",
                }, d: "M125.6,81.2h-17.2v-9.5c0-4.9,0.4-7.5,7.5-7.5h9.5v-19h-15.2c-18.1,0-24.5,9.3-24.5,24.5V81H74.4v19 h11.3v54.8h22.8V100h15.2L125.6,81.2z" })] })));
}
exports.default = Svg;
