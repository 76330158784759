"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 200 200" }, props, { children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("style", { children: ".cls-1{fill:#87c4ed;}.cls-2{fill:#247bc5;}.cls-3{fill:#f29f00;}" }) }), (0, jsx_runtime_1.jsxs)("g", __assign({ id: "Guidelines" }, { children: [(0, jsx_runtime_1.jsx)("path", { className: "cls-1", d: "M116.22,111.73V57.81c0-.88,0-.88.89-.88h22c.86,0,.86,0,.86.89,0,2.67,0,5.34,0,8,0,.58-.18.72-.74.72-3.19,0-6.38,0-9.57,0-.87,0-.88,0-.88.87q0,44.31,0,88.64c0,.71.2.86.88.85,3.16,0,6.33,0,9.5,0,.59,0,.84.12.83.78,0,2.76,0,5.52,0,8.27,0,.51-.13.66-.64.66-7.5,0-15,0-22.49,0-.69,0-.64-.34-.64-.8Z" }), (0, jsx_runtime_1.jsx)("path", { className: "cls-1", d: "M83.69,111.75V165.6c0,1,0,1-.93,1-7.32,0-14.63,0-21.94,0-.68,0-.83-.2-.82-.84,0-2.73,0-5.47,0-8.2,0-.55.17-.69.7-.68,3.19,0,6.38,0,9.57,0,.72,0,.85-.22.85-.88q0-44.33,0-88.64c0-.72-.23-.87-.9-.87-3.23,0-6.47,0-9.7,0-.45,0-.51-.12-.51-.52,0-2.82,0-5.65,0-8.47,0-.55.2-.63.68-.63,7.43,0,14.85,0,22.28,0,.63,0,.74.21.74.78q0,20.37,0,40.73Z" }), (0, jsx_runtime_1.jsx)("path", { className: "cls-2", d: "M107.36,111.82v53.79c0,1,0,1-1,1-4.26,0-8.52,0-12.78,0-.52,0-.64-.13-.64-.64q0-54.19,0-108.4c0-.53.16-.63.65-.63q6.53,0,13.06,0c.57,0,.71.14.71.71Q107.34,84.73,107.36,111.82Z" }), (0, jsx_runtime_1.jsx)("path", { className: "cls-3", d: "M93,40.78c0-2.25,0-4.51,0-6.76,0-.48.13-.61.61-.61q6.59,0,13.18,0c.45,0,.63.08.62.59q0,6.8,0,13.58c0,.47-.12.62-.6.62q-6.63,0-13.25,0C93,48.2,93,48,93,47.61,93,45.33,93,43.06,93,40.78Z" })] }))] })));
}
exports.default = Svg;
