"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { x: 0, style: {
                    fill: "#1F8DED",
                }, width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { style: {
                    fill: "#FFFFFF",
                }, d: "M142.3,46l-82.5,0C52.2,46,46,52.2,46,59.7l0,82.5c0,7.6,6.2,13.8,13.8,13.8l82.5,0 c7.6,0,13.8-6.2,13.8-13.7l0-82.5C156,52.2,149.8,46,142.3,46z M115.7,66.2c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7l0,49 c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7L115.7,66.2z M97.3,64.3c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7l0,53.2 c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7L97.3,64.3z M79,66.2c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7l0,49c0,2-1.6,3.7-3.7,3.7 s-3.7-1.6-3.7-3.7L79,66.2z M60.7,73.5c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7v33c0,2-1.6,3.7-3.7,3.7s-3.7-1.6-3.7-3.7L60.7,73.5 z M140.1,129.4c-0.6,0.5-14.1,11.9-39.1,11.9s-38.5-11.4-39.1-11.9c-1.5-1.3-1.7-3.6-0.4-5.2c1.3-1.5,3.6-1.7,5.2-0.4 C66.9,124,79.1,134,101,134c22.2,0,34.2-10,34.3-10.1c1.5-1.3,3.9-1.1,5.2,0.4C141.8,125.8,141.6,128.1,140.1,129.4z M141.3,106.5 c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7v-33c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7V106.5z" })] })));
}
exports.default = Svg;
