"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsxs)("linearGradient", __assign({ id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 100, y1: 0, x2: 100, y2: 200 }, { children: [(0, jsx_runtime_1.jsx)("stop", { offset: 0, style: {
                                    stopColor: "#F99916",
                                } }), (0, jsx_runtime_1.jsx)("stop", { offset: 1, style: {
                                    stopColor: "#F86606",
                                } })] })), (0, jsx_runtime_1.jsx)("rect", { fill: "url(#SVGID_1_)", width: 200, height: 200 })] }), (0, jsx_runtime_1.jsx)("polygon", { fill: "#FFFFFF", points: "61.4,159.8 61.4,40.2 83.9,40.2 83.9,88 116.1,88 116.1,40.2 138.6,40.2 138.6,159.8 116.1,159.8  116.1,108.3 83.9,108.3 83.9,159.8 " })] })));
}
exports.default = Svg;
