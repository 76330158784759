"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { fill: "#FF5959", width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { id: "Fill-1_2_", fill: "#FFFFFF", d: "M111.8,37c-0.4,0-0.8,0.2-1.2,0.4l-9.4,6.7c-0.7,0.6-1.7,0.6-2.3,0l-9.4-6.7 c-0.3-0.3-0.7-0.4-1.2-0.4H46.6c-0.5,0-1,0.2-1.3,0.6C32.8,50.8,25.1,68.6,25,88.2c-0.2,40.6,33,74.2,73.9,74.8 c41.9,0.6,76.1-33,76.1-74.5c0-19.7-7.7-37.6-20.3-50.9c-0.3-0.4-0.8-0.6-1.3-0.6H111.8z M149.2,89.3c0,28-23.7,50.9-52.3,49.4 c-25.5-1.3-45-22.3-45.9-47.6C50.6,78,54.9,66,62.9,57c0.6-0.7,0.6-1.7-0.1-2.4L48.1,40.2l49.8,35.5c1.3,1,3.2,1,4.5,0l49.8-35.5 l-15,14.3c-0.7,0.7-0.7,1.7-0.1,2.4C144.7,65.6,149.2,76.9,149.2,89.3z" })] })));
}
exports.default = Svg;
